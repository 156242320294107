exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-hasbro-partnership-mdx": () => import("./../../../src/pages/articles/hasbro-partnership.mdx" /* webpackChunkName: "component---src-pages-articles-hasbro-partnership-mdx" */),
  "component---src-pages-articles-kickstarter-launch-mdx": () => import("./../../../src/pages/articles/kickstarter-launch.mdx" /* webpackChunkName: "component---src-pages-articles-kickstarter-launch-mdx" */),
  "component---src-pages-articles-sword-and-sorcery-mdx": () => import("./../../../src/pages/articles/sword-and-sorcery.mdx" /* webpackChunkName: "component---src-pages-articles-sword-and-sorcery-mdx" */),
  "component---src-pages-articles-world-of-darkness-mdx": () => import("./../../../src/pages/articles/world-of-darkness.mdx" /* webpackChunkName: "component---src-pages-articles-world-of-darkness-mdx" */),
  "component---src-pages-cookiepolicy-tsx": () => import("./../../../src/pages/cookiepolicy.tsx" /* webpackChunkName: "component---src-pages-cookiepolicy-tsx" */),
  "component---src-pages-documentation-tsx": () => import("./../../../src/pages/documentation.tsx" /* webpackChunkName: "component---src-pages-documentation-tsx" */),
  "component---src-pages-download-sdk-tsx": () => import("./../../../src/pages/download-sdk.tsx" /* webpackChunkName: "component---src-pages-download-sdk-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-milanuprising-tsx": () => import("./../../../src/pages/milanuprising.tsx" /* webpackChunkName: "component---src-pages-milanuprising-tsx" */),
  "component---src-pages-modena-2022-old-tsx": () => import("./../../../src/pages/modena2022_old.tsx" /* webpackChunkName: "component---src-pages-modena-2022-old-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-sdk-tsx": () => import("./../../../src/pages/sdk.tsx" /* webpackChunkName: "component---src-pages-sdk-tsx" */),
  "component---src-pages-thebadkarmas-curse-of-cthulhu-tsx": () => import("./../../../src/pages/thebadkarmas-curse-of-cthulhu.tsx" /* webpackChunkName: "component---src-pages-thebadkarmas-curse-of-cthulhu-tsx" */),
  "component---src-pages-thebadkarmas-tsx": () => import("./../../../src/pages/thebadkarmas.tsx" /* webpackChunkName: "component---src-pages-thebadkarmas-tsx" */)
}

