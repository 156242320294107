import { GatsbyBrowser } from "gatsby";

const listOfMetricsSend = new Set();

declare global {
  var dataLayer: any[] | undefined;
}

function debounce(fn: any, timeout: number) {
  let timer = null;

  return function (...args: any[]) {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(fn, timeout, ...args);
  };
}

async function sendWebVitals() {
  function sendData(data: any) {
    if (listOfMetricsSend.has(data.name)) {
      return;
    }
    listOfMetricsSend.add(data.name);

    sendToGTM(data, (window.dataLayer ??= []));
  }

  const { onLCP, onFID, onCLS } = await import(`web-vitals/base`);
  const debouncedCLS = debounce(sendData, 3000);
  const debouncedFID = sendData;
  const debouncedLCP = debounce(sendData, 3000);
  onCLS(debouncedCLS, { reportAllChanges: true });
  onFID(debouncedFID, { reportAllChanges: true });
  onLCP(debouncedLCP, { reportAllChanges: true });
}

function sendToGTM({ name, value, id }, dataLayer: any[]) {
  dataLayer.push({
    event: `core-web-vitals`,
    webVitalsMeasurement: {
      name: name,
      id,
      value: Math.round(name === `CLS` ? value * 1000 : value),
    },
  });
}

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = (_, { includeInDevelopment }) => {
  if (process.env.NODE_ENV === `production` || includeInDevelopment) {
    setTimeout(() => {
      const data = window.dataLayer;
      const eventName = `gatsby-route-change`;
      data.push({ event: eventName });
    }, 50);
  }
};
export const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] = (
  _,
  { includeInDevelopment, enableWebVitalsTracking },
) => {
  if ((process.env.NODE_ENV === `production` || includeInDevelopment) && enableWebVitalsTracking) {
    sendWebVitals();
  }
};
